import { z as zod } from "zod";

export const courseTaskStatuses = ["available", "done", "in-progress"] as const;

const courseTaskStatusSchema = zod.enum(courseTaskStatuses);

export type CourseTaskStatus = zod.infer<typeof courseTaskStatusSchema>;

const pluginSchema = zod.object({
  score: zod.number(),
  totalScore: zod.number(),
  title: zod.string().nullish(),
  status: courseTaskStatusSchema.nullish(),
});

const pluginsSchema = zod.array(pluginSchema).nullish();

const courseMenuTaskSchema = zod.object({
  id: zod.number(),
  name: zod.string(),
  status: courseTaskStatusSchema.nullish(),
  plugins: pluginsSchema
});

export default courseMenuTaskSchema;

export type CourseMenuTask = zod.infer<typeof courseMenuTaskSchema>;

export const courseMenuTaskArraySchema = zod.array(courseMenuTaskSchema);
