import { z as zod } from "zod";

import courseMenuTaskSchema, { type CourseMenuTask } from "./task";

const baseCourseMenuTaskModuleSchema = zod.object({
  id: zod.number(),
  total_score: zod.number().nullish(),
  score: zod.number().nullish(),
  name: zod.string(),
  is_enabled: zod.boolean().nullish(),
});

export type CourseMenuTaskModule = zod.infer<
  typeof baseCourseMenuTaskModuleSchema
> & {
  tasks: Array<CourseMenuTask | CourseMenuTaskModule>;
};

const courseMenuTaskModuleSchema: zod.ZodType<CourseMenuTaskModule> =
  baseCourseMenuTaskModuleSchema.extend({
    tasks: zod.array(
      zod.union([
        zod.lazy(() => courseMenuTaskModuleSchema),
        courseMenuTaskSchema,
      ])
    ),
  });

export default courseMenuTaskModuleSchema;

export const courseMenuTaskModuleArraySchema = zod.array(
  courseMenuTaskModuleSchema
);
