import { formatProgramComponent } from "@/features/course-menu/utils/formatProgramComponent";
import type { CourseMenuTask } from "@/schemas/course/menu/task";
import type { CourseMenuTaskModule } from "@/schemas/course/menu/task-module";

import { isModule } from "./type";

export interface ExtendedCourseMenuTask extends CourseMenuTask {
  number?: string;
}

export interface ExtendedCourseMenuTaskModule
  extends Omit<CourseMenuTaskModule, "tasks"> {
  number?: string;
  type?: "module" | "topic";

  tasks: Array<ExtendedCourseMenuTask | ExtendedCourseMenuTaskModule>;
}

export default function extendTaskModules(
  modules: CourseMenuTaskModule[]
): ExtendedCourseMenuTaskModule[] {
  const transformModule = (
    module: CourseMenuTaskModule,
    base: string | undefined,
    index: number
  ): ExtendedCourseMenuTaskModule => {
    const moduleBase = formatProgramComponent(index, base);

    const isTopicModule = base !== undefined;

    return {
      ...module,
      number: moduleBase,
      tasks: module.tasks.map((task, taskIndex) => {
        return isModule(task)
          ? transformModule(
              task,
              isTopicModule ? formatProgramComponent(taskIndex, base) : "",
              taskIndex
            )
          : {
              ...task,
              number: formatProgramComponent(
                taskIndex,
                isTopicModule ? moduleBase : ""
              ),
            };
      }),
      type: isTopicModule ? "topic" : "module",
    };
  };

  return modules.map((module, i) => transformModule(module, undefined, i));
}
