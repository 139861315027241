import ky from "ky";

import { NetworkError } from "@/errors/http/NetworkError";
import { kyInjectAuth } from "@/utils/fetch/injectAuthInterceptor";
import { kyHandleUnauthorized } from "@/utils/fetch/unauthorizedInterceptor";
import { kyUnwrapError } from "@/utils/fetch/unwrapError";

export const kyFetcher = ky.create({
  prefixUrl: import.meta.env.APP_API_URL,
  timeout: 30_000,
  hooks: {
    beforeRequest: [kyInjectAuth],
    afterResponse: [kyHandleUnauthorized],
    beforeError: [kyUnwrapError],
  },

  fetch: (...args) =>
    fetch(...args).catch((error) => {
      if (error instanceof TypeError) {
        throw new NetworkError(error);
      }

      throw error;
    }),
});
