import { createMutation } from "react-query-kit";

import { kyFetcher } from "@/api/fetcher";
import {
  courseCommonInfoSchema,
  courseResponseDataSchema,
} from "@/schemas/course/course";
import { courseMenuSchema, courseProgressSchema } from "@/schemas/course/menu/menu";
import { courseMenuTaskModuleArraySchema } from "@/schemas/course/menu/task-module";
import { courseParametersSchema } from "@/schemas/course/parameters";
import { selectCohortSchema } from "@/schemas/course/select-cohort";
import { coursesResponseSchema } from "@/schemas/courses";
import { successfulResponse } from "@/schemas/response";
import { createQueryWithSchema } from "@/utils/fetch/createQueryFunction";
import noop from "@/utils/noop";
import filterSearchParams from "@/utils/query/filterSearchParams";

export const useSingleCourseQuery = createQueryWithSchema(
  successfulResponse(courseResponseDataSchema)
)<{ course: string; cohort?: number }>({
  primaryKey: "course/course",
  queryFn: async ({ queryKey: [, { course: url, cohort }], signal }) => {
    return await kyFetcher
      .get(`courses/${url}`, {
        searchParams: filterSearchParams({
          cohort,
        }),
        signal,
      })
      .json();
  },
});

export const useCourseMenuQuery = createQueryWithSchema(
  successfulResponse(courseMenuSchema)
)<{ url: string; cohort?: number }>({
  primaryKey: "course/menu",
  queryFn: async ({ queryKey: [, { url, cohort }], signal }) => {
    return await kyFetcher
      .get(`courses/${url}/menu`, {
        searchParams: filterSearchParams({
          cohort,
        }),
        signal,
      })
      .json();
  },
});

export const useCourseProgressQuery = createQueryWithSchema(
  successfulResponse(courseProgressSchema)
)<{ url: string; cohort?: number }>({
  primaryKey: "course/progress",
  queryFn: async ({ queryKey: [, { url, cohort }], signal }) => {
    return await kyFetcher
      .get(`courses/${url}/progress`, {
        searchParams: filterSearchParams({
          cohort,
        }),
        signal,
      })
      .json();
  },
});

export const useCourseMenuCohortLastVisitMutation = createMutation({
  mutationFn: async ({
    course,
    cohort,
  }: {
    course: string;
    cohort: number;
  }) => {
    return await kyFetcher
      .put(`courses/${course}/cohorts/${cohort}/save-last-visit`)
      .catch(noop);
  },
});

export const useCourseCommonInfoQuery = createQueryWithSchema(
  successfulResponse(courseCommonInfoSchema)
)<{ course: string }>({
  primaryKey: "course/info",
  queryFn: async ({ queryKey: [, { course }], signal }) => {
    return await kyFetcher.get(`courses/${course}/common`, { signal }).json();
  },
});

export const useCohortsListQuery = createQueryWithSchema(
  successfulResponse(selectCohortSchema)
)<{
  course: string;
}>({
  primaryKey: "course/cohorts",
  queryFn: async ({ queryKey: [, { course }], signal }) => {
    return await kyFetcher.get(`courses/${course}/cohorts`, { signal }).json();
  },
});

export const useCohortModulesQuery = createQueryWithSchema(
  successfulResponse(courseMenuTaskModuleArraySchema)
)<{ course: string; cohort: number }>({
  primaryKey: "course/cohort-modules",
  queryFn: async ({ queryKey: [, { course, cohort }], signal }) => {
    return await kyFetcher
      .get(`courses/${course}/cohorts/${cohort}/modules`, { signal })
      .json();
  },
});

export const useCourseParametersQuery = createQueryWithSchema(
  successfulResponse(courseParametersSchema)
)<{ course: string; cohort: number | undefined }>({
  primaryKey: "course/parameters",
  queryFn: async ({ queryKey: [, { course, cohort }], signal }) => {
    return await kyFetcher
      .get(`courses/${course}/configs`, {
        searchParams: filterSearchParams({
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          cohort,
        }),
        signal,
      })
      .json();
  },
});

export { useLiveClassCalendarQuery as useCohortLiveClassCalendarQuery } from "./course/live-classes";

export const useCoursesListQuery = createQueryWithSchema(
  successfulResponse(coursesResponseSchema)
)({
  primaryKey: "courses/list",
  queryFn: async () => {
    return await kyFetcher.get("courses").json();
  },
});
